import { graphql, useStaticQuery } from 'gatsby';
import React, { ReactNode } from 'react';
import 'reflect-metadata';
import Layout from '../layout';
import styled from 'styled-components';
import "../index.scss"
import { Row } from '../styles/grids';

interface IndexPageProps {
    children: ReactNode;
}

interface PhotoData {
    contentfulCoverPhoto: {
        photo: {
            file: {
                url: string
            };
        }
    };
}

const Center = styled.p`
  text-align: center;
  color: #F3867F;
`;

const PhotoContainer = styled.div`
  margin: 0 auto;
  max-width: 1280px;
  padding: 0px 1.0875rem;
  padding-top: 1vw;
  overflow: hidden;
  img {
      height: 60vh;
      width: auto;
  }

  @media only screen and (width: 768px) and (width: 1024px) {
    padding-top: 15vw;
    img {
      height: 50vh;
      width: auto;
    }
  }

  @media only screen and (width: 1024px) and (height: 768px) {
    padding-top: 5vw;
    img {
      height: 50vh;
      width: auto;
    }
  }

  @media only screen and (max-width: 767px) {
    padding-top: 30vw;
    img {
      height: 30vh;
      width: auto;
    }
  }

  @media only screen and (min-width: 415px) and (min-height: 374px) and (max-width: 767px) and (max-width: 1024px) {
    padding-top: 3vw;
    img {
      height: 30vh;
      width: auto;
    }
  }
`;


const IndexPage: React.FC<IndexPageProps> = () => {

    const { contentfulCoverPhoto }: PhotoData = useStaticQuery(graphql`
        query {
            contentfulCoverPhoto {
                photo {
                    file {
                        url
                    }
                }
            }
        }
    `);

    return (
        <Layout>
            <PhotoContainer>
                {/* <Row> */}
                    <Center>
                        <img src={`https:${contentfulCoverPhoto.photo.file.url}`} /> 
                    </Center>
                {/* </Row> */}
            </PhotoContainer>
        </Layout>
    );
};

export default IndexPage;
